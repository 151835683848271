<template>
  <div class="">




    <!-- Table Container Card -->
    <b-card no-body class="mb-0  match-height">

      <div class="ml-2 mb-2 mt-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('Show') }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search...')" />

            </div>
          </b-col>
        </b-row>

      </div>

      <!-- blogs -->
      <b-row class="match-height">
        <b-col md="9" style="min-height:300px;">


          <b-table ref="refUserListTable" class="position-relative" :items="fetchInstructers" responsive
            :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
            :empty-text="$t('No matching records found')" :sort-desc.sync="isSortDirDesc">

            <template #head()="data">
              <span class="text">{{ $t(data.label.toUpperCase()) }}</span>
            </template>
            <template #cell(title)="data">
              <b-media vertical-align="center">

                <b-link :to="{ name: 'cms-blog-edit', query: { p: data.item.id }, params: { id: data.item.id } }"
                  class="font-weight-bold d-block text-nowrap">
                  {{ data.item.title }}
                </b-link>
                <small class="text-muted">@{{ data.item.author }}</small>

              </b-media>
            </template>



            <template #cell(createdAt)="data">
              <div class="text-nowrap">

                <span class="align-text-top text-capitalize">{{ data.item.createdAt | formattedDate }}</span>
              </div>
            </template>
            <template #cell(author)="data">
              <b-media vertical-align="center" v-if="data.item.publisher != null && data.item.publisher != undefined">
                <template #aside>
                  <b-avatar size="32" :src="data.item.publisher.image" :text="avatarText(data.item.publisher.title)"
                    :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                    :to="{ name: 'apps-instructor-view', params: { id: data.item.id } }" />
                </template>
                <b-link :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
                  class="font-weight-bold d-block text-nowrap">
                  {{ data.item.publisher == null || data.item.publisher == undefined ? '' : data.item.publisher.title }}
                </b-link>
                <small class="text-muted"> {{ data.item.publisher == null || data.item.publisher == undefined ? '' :
                  data.item.publisher.headline }}</small>

              </b-media>
            </template>
            <!-- Column: User -->
            <template #cell(featuredImage)="data">
              <b-media vertical-align="center">
                <template #aside>

                  <b-img thumbnail width="100" height="100" :src="data.item.featuredImage" />


                </template>

              </b-media>
            </template>
            <!-- Column: Role -->


            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.status)}`" class="text-capitalize">
                {{ resolveUserStatusVariantText(data.item.status) }}

              </b-badge>
            </template>


            <!-- Column: Actions -->
            <template #cell(actions)="data" v-if="1 > 2">
              <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                <template #button-content>

                  <b-button variant=".btn-flat-dark" @click="changeCategory(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ $t("Edit") }}</span>
                  </b-button>
                </template>

              </b-dropdown>
            </template>

          </b-table>
        </b-col>


        <b-col md="3" style="min-height:300px;">
          <div slot="sidebarz" class="  py-2 py-lg-0">
            <div class="  mt-3">

              <b-link :to="{ name: 'blogpost-new', params: { id: 0 } }" class="font-weight-bold d-block text-nowrap">
                <span class="text-nowrap">{{ $t('Create New Post') }}</span>
              </b-link>

            </div>


            <!-- categories -->
            <div class="blog-categories mt-3">
              <h6 class="section-label mb-1">
                {{ $t('Categories') }}
              </h6>
              <b-link>
                <b-avatar rounded size="32" :variant="tagsColor()" class="mr-75">
                  <feather-icon icon="ArrowRightCircleIcon" size="16" />
                </b-avatar>
              </b-link>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary"
                @click="updatSelectedCategory(null)">
                <div class="blog-category-title text-body">
                  All
                </div>
              </b-button>
              <div v-for="category in allCategories" :key="category.icon"
                class="d-flex justify-content-start align-items-center mb-75">
                <b-link>
                  <b-avatar rounded size="32" :variant="tagsColor(category.title)" class="mr-75">
                    <feather-icon icon="ArrowRightCircleIcon" size="16" />
                  </b-avatar>
                </b-link>
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="selectedCategory != null && selectedCategory.id == category.id ? 'primary' : 'flat-primary'"
                  @click="updatSelectedCategory(category)">
                  <div class="blog-category-title text-body">
                    {{ category.title }}
                  </div>
                </b-button>
              </div>
            </div>
            <!--/ categories -->
          </div>

        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <!-- pagination -->

          <div class="my-2">
            <b-pagination v-model="currentPage" align="center" :total-rows="totalUsers" :per-page="perPage" first-number
              last-number prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
      <!--/ blogs -->

      <!-- sidebar -->



      <!--/ sidebar -->

    </b-card>
  </div>

</template>

<script>
import {
  BTable, BImgLazy, BRow, BDropdown, BCol, BButton, BCard, BFormInput, BCardText, BCardTitle, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import userStoreModule from '../departmentStoreModule'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './DepartmentsListFilters.vue'
import useUsersList from './useDepartmentsList'
import moment from 'moment'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  components: {
    BTable, BButton, BDropdown, vSelect,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BImgLazy,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
  },
  filters: {
    formattedDate: function (value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY HH:mm')
      }
    }
  },
  data() {
    return {
      search_query: '',


    }
  },
  created() {
    // this.$http.get('/blog/list/data').then(res => { this.blogList = res.data })
    //  this.$http.get('/blog/list/data/sidebar').then(res => { this.blogSidebar = res.data })
  },
  methods: {
    kFormatter,
    tagsColor(tag) {
      if (tag === 'HR Commmunication') return 'light-info'
      if (tag === 'Weekly News') return 'light-danger'
      if (tag === 'Announcement') return 'light-primary'
      if (tag === 'Memorandum') return 'light-warning'
      if (tag === 'Info') return 'light-success'
      return 'light-primary'
    },

    updatSelectedCategory(category) {
      this.selectedCategory = category
    },
    moment: function () {
      return moment();
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'apps-bloglist'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchInstructers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      allCategories,
      selectedCategory,


    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchInstructers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      allCategories,
      selectedCategory
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
